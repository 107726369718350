<template>
  <div class="ceyrekAnaliz">
    <v-card v-if="loading" rounded="xl" min-height="700" class="mb-4">
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </v-card>
    <v-card v-else rounded="xl" class="mb-4">
      <v-list color="grey">
        <v-list-item :dense="$vuetify.breakpoint.xsOnly">
          <v-list-item-icon>
            <v-icon :size="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 36 : null">
              {{ icons.mdiCircleDouble }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :style="!$vuetify.breakpoint.mdAndDown ? null : 'font-size:13px'">
              2024 Yılı 2. Çeyrek Satış Analiz</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action v-if="!$vuetify.breakpoint.mdAndDown">
            <v-btn-toggle depressed v-model="salesType" rounded mandatory>
              <v-btn> TON </v-btn>
              <v-btn> TL </v-btn>
            </v-btn-toggle>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-container class="grey" v-if="$vuetify.breakpoint.mdAndDown">
        <v-row >
          <v-col cols="7">
            <v-select
              rounded
              filled
              hide-details
              background-color="nightDarkTon"
              dense
              v-model="reportSelected"
              :items="mounts"
            ></v-select>
          </v-col>
          <v-col cols="5">
            <v-btn-toggle rounded block v-model="salesType" mandatory>
              <v-btn> TON </v-btn>
              <v-btn> TL </v-btn>
            </v-btn-toggle></v-col
          >
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <vue-apex-charts
        ref="apexChart"
        type="line"
        height="360"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
      <v-card-text class="px-2" v-if="!$vuetify.breakpoint.mdAndDown">
        <v-item-group v-model="reportSelected" mandatory>
          <v-container>
            <v-row>
              <v-col v-for="(reportData, index) in reportDatas" :key="index" cols="12" md="4">
                <v-item v-slot="{ active, toggle }" class="rounded-xl overflow-hidden">
                  <v-card
                    @click="toggle"
                    :rounded="$vuetify.breakpoint.smAndDown ? 'lg' : 'xl'"
                    :color="active ? 'secondary' : 'grey'"
                  >
                    <v-card-title style="color: white !important">{{ reportData.month }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text :class="active ? 'secondary-light' : 'grey'">
                      <template v-for="(item, i) in reportData.data">
                        <v-list-item class="px-0" :dense="$vuetify.breakpoint.smAndDown" :key="i">
                          <v-list-item-content :style="$vuetify.breakpoint.smAndDown ? 'flex-wrap: initial;' : null">
                            <v-list-item-subtitle> {{ item.text }}</v-list-item-subtitle>
                            <v-list-item-title>{{
                              item.amount | currency({ symbol: '', fractionCount: 0 })
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="reportData.data.length > i + 1" />
                      </template>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-card-text>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <v-container class="px-5 pb-16">
          <v-row>
            <v-col v-for="(reportData, index) in reportDatas" :key="index" cols="12">
              <v-card flat rounded="xl" color="nightDark">
                <v-card-title class="text-subtitle-1 font-weight-bold" style="color: white !important">
                  {{ reportData.month }}
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense color="nightDark">
                  <template v-for="(item, i) in reportData.data">
                    <v-list-item>
                      <v-list-item-content class="text-body-2">{{ item.text }}</v-list-item-content>
                      <v-list-item-content class="text-subtitle-2 align-end justify-end" style="width: 100px">
                        {{ item.amount | currency({ symbol: '', fractionCount: 0 }) }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="reportData.data.length > i + 1" />
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { mdiRefresh, mdiCircleDouble, mdiMapMarker } from '@mdi/js'
import moment from 'moment'
import { postData } from '@utils'
import { getVuetify } from '@core/utils'
import { currency } from '@/utils'
export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const loading = ref(true)
    const $vuetify = getVuetify()

    const getDateRange = (firstDate, lastDate) => {
      if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day')) return [lastDate]
      let date = firstDate
      const dates = [date]
      do {
        date = moment(date).add(1, 'day')
        dates.push(date.format('YYYY-MM-DD'))
      } while (moment(date).isBefore(lastDate))
      return dates
    }

    const apexChart = ref(null)
    const dates = ref([])
    const salesType = ref(0)
    const salesText = ['TON', 'TL']
    const data = ref({ detail: [{ daily: [] }], fknsList: [{ daily: [] }] })
    const reportSelected = ref(null)
    const reportDatas = ref([])
    const mounts = ref([
      {
        text: 'Nisan',
        value: 0,
      },
      {
        text: 'Mayıs',
        value: 1,
      },
      {
        text: 'Haziran',
        value: 2,
      },
    ])
    const getTurnoverReport = () => {
      loading.value = true
      postData({
        method: 'getTurnoverReport2',
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          // API'dan gelen veriler işleniyor
          reportDatas.value = response.detail
          reportSelectedFirst()
          // data.value = response
          // series.value[0].data = response.detail[0].daily
          // series.value[1].data = [0, 2, 2, 3, 1, 0, 4, 2, 1, 4, 0, 1]
        })
        .catch(e => {
          // API hataya girdi
          console.table(e)
        })
        .finally(() => {
          setTimeout(() => {
            loading.value = false
          }, 600)
          // API işlemi bittikten sonra
        })
    }

    const reportSelectedFirst = () => {
      reportDatas.value.forEach((item, index) => {
        if (moment().month() + 1 === moment(item.start, 'YYYY.MM.DD').month() + 1) {
          reportSelected.value = index
        }
      })
    }

    watch([reportSelected, salesType], ([val, type]) => {
      const START = moment(reportDatas.value[val].start, 'DD.MM.YYYY').format('YYYY-MM-DD')
      const END = moment(reportDatas.value[val].end, 'DD.MM.YYYY').format('YYYY-MM-DD')

      dates.value = getDateRange(START, END)

      if (apexChart.value) {
        chartOptions.value = {
          labels: dates.value,
          // yaxis: { title: { text: 'Satış (' + salesText[salesType.value] + ')' } },
        }
        apexChart.value.updateSeries(
          [
            {
              data: reportDatas.value[val].data[type + 1].daily,
            },
          ],
          true,
          true,
        )
      } else {
        chartOptions.value.labels = dates.value
        series.value[0].data = reportDatas.value[val].data[type + 1].daily
      }
    })

    onMounted(() => {
      getTurnoverReport()
    })

    const chartOptions = ref({
      chart: {
        height: 600,
        type: 'line',
        id: 'areachart-2',
        toolbar: {
          show: false,
        },
      },

      stroke: {
        curve: 'straight',
      },

      grid: {
        padding: {
          right: 42,
          left: 32,
          bottom: 30,
        },
      },

      stroke: {
        width: [3, 3],
        dashArray: [0, 0],
      },

      labels: dates.value,

      xaxis: {
        type: 'datetime',

        labels: {
          formatter: function (value) {
            return moment(value).format('DD MMM')
          },
        },
      },

      colors: ['#008ffb'],

      yaxis: {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008ffb',
        },
        title: {
          text: '',
          offsetY: -146,
          offsetX: 70,
          rotate: 0,
          style: {
            color: '#008ffb',
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        labels: {
          style: {
            colors: '#008ffb',
          },
          formatter: function (value) {
            const result = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value)
            return result.slice(1).slice(0, -3) + ' ' + salesText[salesType.value]
          },
        },
      },
    })

    const series = ref([
      {
        name: 'Satış',
        data: [],
      },
    ])

    return {
      chartOptions,
      series,
      loading,
      data,
      apexChart,
      mounts,
      salesType,
      reportDatas,
      reportSelected,
      icons: {
        mdiCircleDouble,
        mdiRefresh,
        mdiMapMarker,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ceyrekAnaliz .v-skeleton-loader {
  height: auto;
  ::v-deep.v-skeleton-loader__image {
    height: 700px !important;
  }
}
.v-list-item__content {
  flex: auto;
}
</style>

<style lang="scss">
.apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 12px !important;
}
</style>
