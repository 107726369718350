<template>
  <v-card class="raporEkran d-flex flex-column" color="transparent" rounded="xl" height="100%">
    <v-dialog v-model="segmantasyonDialog" width="600" scrollable>
      <v-card>
        <v-card-title class="text-body-1 text-md-h5 grey">
          Segmentasyon
          <v-spacer />
          <v-btn icon @click="segmantasyonDialog = !segmantasyonDialog">
            <v-icon>{{ simgeler.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 80dvh" class="pa-0">
          <v-list> </v-list>
          <v-list class="mb-8 mb-md-16" dense nav v-for="(eleman, i1) in segmantasyonBilgileri" :key="'el-' + i1">
            <v-list-item two-line>
              <v-list-item-avatar :size="$vuetify.breakpoint.smAndDown ? 36 : 64" :color="eleman.color">
                <v-avatar :color="eleman.color">
                  <span class="text-md-h5">
                    {{ eleman.reyting }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  style="overflow: initial"
                  :class="eleman.baslik === 'Durumsuz' ? 'text-md-h6 primary--text' : 'text-h6 text-md-h4'"
                >
                  {{ eleman.baslik }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-chip color="secondary" class="text-caption px-6">
                  {{ eleman.aralik }} <span class="ms-2" v-if="!$vuetify.breakpoint.smAndDown">Puan Aralığında</span>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <template v-for="(madde, i2) in eleman.maddeler">
              <v-list-item :key="'madde-' + i2">
                <v-list-item-icon v-if="!$vuetify.breakpoint.smAndDown">
                  <v-icon>{{ simgeler.mdiMinus }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-md-subtitle-1 font-weight-regular" style="white-space: initial">{{
                    madde
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="i2 < eleman.maddeler.length - 1" :key="i2" />
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="segmantasyonDetailDialog" width="600">
      <v-card>
        <v-card-title class="text-body-1 text-md-h5 grey">
          Puan Hesaplaması
          <v-spacer />
          <v-btn icon @click="segmantasyonDetailDialog = !segmantasyonDetailDialog">
            <v-icon>{{ simgeler.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Açıklama</th>
                  <th class="text-right">Etki %</th>
                  <th class="text-right">Alınan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in segmantasyonDegerleri.detail" :key="item.name">
                  <td>{{ item.stext }}</td>
                  <td class="text-right">{{ item.percent }}</td>
                  <td class="text-right">{{ item.ratingval }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-list color="transparent">
      <v-list-item>
        <v-list-item-icon>
          <v-icon :size="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 36 : null">
            {{ simgeler.mdiChartDonutVariant }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :style="!$vuetify.breakpoint.mdAndDown ? null : 'font-size:13px'">
            {{ segmantasyonDegerleri.text }} Segmentasyon Raporu</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text class="flex-grow-1" style="position: relative">
      <div class="segmantasyon-kapsul">
        <div class="segmantasyon-yuzey">
          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-background"
            :src="require(`@/assets/images/chart/segmantasyon-background.png`)"
          />

          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-olcu"
            :src="require(`@/assets/images/chart/segmantasyon-olcu.png`)"
          />
          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-zemin"
            :src="require(`@/assets/images/chart/segmantasyon-zemin.png`)"
          />

          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-one"
            :src="require(`@/assets/images/chart/segmantasyon-${segmantasyonDegerleri.durum}-one.png`)"
          />

          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-mobil-one"
            :src="require(`@/assets/images/chart/segmantasyon-img-mobil-${segmantasyonDegerleri.durum}.png`)"
          />

          <template v-if="segmantasyonDegerleri.goster">
            <img
              class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-two"
              :src="require(`@/assets/images/chart/segmantasyon-${segmantasyonDegerleri.durum}-two.png`)"
            />

            <img
              class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-three"
              :src="require(`@/assets/images/chart/segmantasyon-${segmantasyonDegerleri.durum}-three.png`)"
            />
          </template>

          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-daha-fazla-bilgi"
            :src="require(`@/assets/images/chart/segmantasyon-daha-fazla-bilgi.png`)"
          />
          <img
            class="segmantasyon-ortalama segmantasyon-img segmantasyon-img-daha-fazla-bilgi-uzerinde"
            :src="require(`@/assets/images/chart/segmantasyon-daha-fazla-bilgi-uzerinde.png`)"
          />

          <div
            class="segmantasyon-ortalama segmantasyon-daha-fazla-bilgi-buton"
            @mouseover="segmantasyonUzerinde = true"
            @mouseleave="segmantasyonUzerinde = false"
            @click="segmantasyonDialog = !segmantasyonDialog"
          />

          <svg class="segmantasyon-ortalama segmantasyon-svg-ibre" width="600" height="600" viewBox="0 0 158.75 158.75">
            <path
              d="m 88.915323,67.6668 -25.789888,0 -25.789889,-10e-7 L 50.23049,45.332101 63.125435,22.997402 76.02038,45.3321 Z"
              transform="matrix(1.0259189,0,0,0.44423589,14.613424,-10.216271)"
            />
          </svg>

          <div class="segmantasyon-ortalama segmantasyon-puan sayi-kaydir" :data-sss="segmantasyonDegerleri.puan">
            {{ segmantasyonDegerleri.puan }}
          </div>
          <div class="segmantasyon-ortalama segmantasyon-sira sayi-kaydir" :data-sss="segmantasyonDegerleri.sira">
            {{ segmantasyonDegerleri.sira }}
          </div>

          <div class="segmantasyon-ortalama segmantasyon-kayit-yok text-center">
            <v-icon size="72" color="primary" class="mb-2">{{ simgeler.mdiAlertCircle }}</v-icon>
            <div class="text-subtitle-2 font-weight-medium primary--text">Kayıt Bulunamadı</div>
          </div>

          <div class="segmantasyon-yukleniyor lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions v-show="!dataLoading">
      <v-row>
        <v-col cols="12" md="7"
          ><v-btn
            class="text-capitalize"
            @click="segmantasyonDetailDialog = !segmantasyonDetailDialog"
            block
            :x-large="!$vuetify.breakpoint.mdAndDown"
            depressed
            rounded
            >Puan Hesabı</v-btn
          ></v-col
        >
        <v-col cols="12" md="5">
          <v-menu attach top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-capitalize"
                color="secondary"
                block
                :x-large="!$vuetify.breakpoint.mdAndDown"
                depressed
                rounded
                v-bind="attrs"
                v-on="on"
              >
                Çeyrek Değiştir
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="item in segmantasyonItems" :key="item.value" @click="segmantasyonIndex = item.value">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getCurrentInstance, onMounted, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Plugin
import gsap from 'gsap'

// icon
import { mdiAlertCircle, mdiMinus, mdiChartDonutVariant, mdiClose } from '@mdi/js'

const yuzdeKarsiliklar = [
  -100, -100, -98, -96, -94, -92, -90, -88, -86, -85, -85, -83, -82, -81, -79, -77, -75, -73, -71, -69, -65, -63, -61,
  -59, -57, -55, -54, -53, -52, -51, -50, -48, -46, -44, -42, -40, -39, -38, -37, -36, -35, -34, -33, -32, -31, -30,
  -29, -28, -27, -26, -25, -23, -21, -19, -17, -15, -13, -11, -9, -7, -5, -3, -1, 1, 3, 5, 7, 9, 11, 13, 25, 30.5, 33,
  35, 37, 39.5, 43, 46, 49, 51, 57, 60, 63, 66, 68, 71, 74.5, 76, 80, 82.5, 88, 92, 94, 97, 100, 102.5, 106, 108.5, 111,
  114, 119,
]

export default {
  emits: ['segmantasyonDegerleri'],
  setup(props, ctx) {
    const vm = getCurrentInstance()?.proxy

    const segmantasyonDetailDialog = ref(false)
    const segmantasyonDetailItems = ref([])
    const segmantasyonDialog = ref(false)
    const segmantasyonBilgileri = [
      {
        baslik: 'Gold',
        reyting: 'AA',
        color: '#ecb106',
        aralik: '100-91',
        maddeler: [
          'Standart komisyona ilave %1 komisyon hak edişi',
          'Ekstra %40 kota hak edişi',
          'Standart promosyon dışında %40 ekstra pormosyon',
          'Gecikme cezalarında %40 indirim',
        ],
      },
      {
        baslik: 'Silver',
        reyting: 'A',
        color: '#616461',
        aralik: '90-81',
        maddeler: [
          'Standart komisyona ilave %0,5 komisyon hak edişi',
          'Ekstra %25 kota hak edişi',
          'Standart promosyon dışında %25 ekstra pormosyon',
          'Gecikme cezalarında %25 indirim',
        ],
      },
      {
        baslik: 'Bronz',
        reyting: 'B',
        color: '#8a533d',
        aralik: '80-71',
        maddeler: [
          'Standart komisyona ilave %0,25 komisyon hak edişi',
          'Ekstra %10 kota hak edişi',
          'Standart promosyon dışında %10 ekstra pormosyon',
          'Gecikme cezalarında %10 indirim',
        ],
      },
      {
        baslik: 'Durumsuz',
        reyting: 'C',
        color: '#aaa6bf',
        aralik: '70-51',
        maddeler: ['Ödül yok'],
      },
      {
        baslik: 'Durumsuz',
        reyting: 'D',
        color: '#FF4C51',
        aralik: '50-0',
        maddeler: ['Ödül yok'],
      },
    ]
    const init = () => {
      gsap.set('.segmantasyon-yuzey', {
        position: 'absolute',
        top: '50%',
        left: '50%',
        x: '-50%',
        y: '-50%',
      })
      gsap.set('.segmantasyon-ortalama', {
        autoAlpha: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        x: '-50%',
        y: '-50%',
      })

      gsap.set('.segmantasyon-img-zemin', {
        autoAlpha: 1,
        scale: 0.5,
      })
    }

    const segmantasyonDegerleri = ref({
      reyting: null,
      durum: 'bronz',
      goster: true,
      ibre_renk: '#fff',
      sira: 0,
      puan: 0,
    })

    const animasyonKayitVar = mobilDurum => {
      const sayiKaydirmalar = document.querySelectorAll('.sayi-kaydir')
      const mobil = mobilDurum ? 0 : 1
      const desktop = mobilDurum ? 1 : 0

      gsap.from(sayiKaydirmalar, {
        textContent: 10,
        duration: 5,
        delay: 0.5,
        snap: {
          textContent: 1,
        },
        ease: 'elastic.out(1,0.9)',
        stagger: {
          each: 1.0,
          onUpdate: function () {
            this.targets()[0].innerHTML = this.targets()[0]
              .textContent.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          },
        },
      })

      if (!segmantasyonDegerleri.value.goster) {
        gsap.fromTo('.segmantasyon-yuzey', { y: '-50%' }, { y: '-43%', duration: 0.5 })
      }

      gsap.to('.segmantasyon-img-zemin', { autoAlpha: 1, scale: 1, duration: 0.8, ease: 'elastic.out(1,0.4)' })

      gsap.fromTo(
        '.segmantasyon-img-background',
        { autoAlpha: 0, rotate: -180, scale: 0.25 },
        { autoAlpha: 1, rotate: 0, scale: 1, duration: 4, ease: 'elastic.out(1,0.6)' },
      )

      gsap.fromTo(
        '.segmantasyon-img-olcu',
        { autoAlpha: 0, rotate: 180, scale: 0.75 },
        { autoAlpha: 1, rotate: 0, scale: 1, duration: 5, ease: 'elastic.out(1,0.3)' },
      )

      gsap.fromTo(
        '.segmantasyon-svg-ibre',
        { autoAlpha: 0, rotate: -360, scale: 1 },
        {
          autoAlpha: 1,
          fill: segmantasyonDegerleri.value.ibre_renk,
          rotate: yuzdeKarsiliklar[segmantasyonDegerleri.value.puan],
          scale: 1,
          duration: 5,
          delay: 0.5,
          ease: 'elastic.out(1,0.9)',
        },
      )

      gsap.to('.segmantasyon-puan', { autoAlpha: 1, delay: 1, duration: 0.5 })
      gsap.to('.segmantasyon-sira', { autoAlpha: 1, delay: 1, duration: 0.5 })

      gsap.fromTo(
        '.segmantasyon-img-one',
        { autoAlpha: 0, rotationY: 125 },
        { autoAlpha: mobil, rotationY: 0, duration: 3, delay: 1.2, ease: 'elastic.out(1,0.4)' },
      )

      gsap.fromTo(
        '.segmantasyon-img-mobil-one',
        { autoAlpha: 0, rotationY: 125 },
        { autoAlpha: desktop, rotationY: 0, duration: 3, delay: 1.2, ease: 'elastic.out(1,0.4)' },
      )

      gsap.fromTo(
        '.segmantasyon-img-two',
        { autoAlpha: 0, y: '-65%' },
        { autoAlpha: mobil, y: '-50%', duration: 0.75, delay: 0.75, ease: 'back.out(1.2)' },
      )

      gsap.fromTo(
        '.segmantasyon-img-three',
        { autoAlpha: 0, y: '-60%' },
        { autoAlpha: mobil, y: '-50%', duration: 1, delay: 1, ease: 'back.out(1.2)' },
      )

      gsap.to('.segmantasyon-img-daha-fazla-bilgi', { autoAlpha: mobil, delay: 1 })
      gsap.to('.segmantasyon-img-daha-fazla-bilgi-uzerinde', { autoAlpha: mobil, delay: 2 })
      gsap.to('.segmantasyon-daha-fazla-bilgi-buton', { autoAlpha: 1, delay: 3 })
    }

    const animasyonKayitYok = () => {
      gsap.to('.segmantasyon-img-zemin', { autoAlpha: 0, scale: 0, duration: 3, ease: 'elastic.out(1,0.3)' })
      gsap.fromTo(
        '.segmantasyon-kayit-yok',
        { autoAlpha: 0, y: '-10%', scale: 0.5 },
        { autoAlpha: 0.8, y: '-50%', scale: 1, duration: 1, delay: 0.25, ease: 'elastic.out(1,0.5)' },
      )
    }

    const dataLoading = ref(true)
    const segmantasyonItems = ref([])
    const segmantasyonIndex = ref(0)
    const segmantasyonHesaplamasi = ref([])
    watch(segmantasyonIndex, val => {
      gsap.set('.segmantasyon-yuzey', {
        position: 'absolute',
        top: '50%',
        left: '50%',
        x: '-50%',
        y: '-50%',
      })

      gsap.set('.segmantasyon-ortalama', {
        autoAlpha: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        x: '-50%',
        y: '-50%',
      })

      gsap.set('.segmantasyon-img-zemin', {
        autoAlpha: 1,
        scale: 0.5,
      })

      segmantasyonHesapla()
      setTimeout(() => {
        const items = document.querySelectorAll('.sayi-kaydir')
        items.forEach(item => {
          item.innerHTML = item.dataset.sss
        })
        animasyonOlustur(true)
      }, 100)
    })

    const segmantasyonVerileri = async () => {
      dataLoading.value = true
      try {
        const data = await store.dispatch('postMethod', { method: 'ESMSCORETABLE' })

        // Back end hata gönderdi
        if (data.error == 1) throw new Error(response.msg)

        // Back end veri göndermedi
        if (!data.response) throw new Error('Kayıt Bulunamadı')

        segmantasyonItems.value = data.response.map((item, i) => {
          return { text: item.QUARTERTXT, value: i }
        })

        segmantasyonHesaplamasi.value = data.response
        segmantasyonHesapla()
        return true
      } catch (e) {
        return false
      } finally {
        dataLoading.value = false
      }
    }

    const segmantasyonHesapla = () => {
      segmantasyonDegerleri.value = {
        text: segmantasyonHesaplamasi.value[segmantasyonIndex.value].QUARTERTXT,
        reyting: segmantasyonHesaplamasi.value[segmantasyonIndex.value].RATING,
        sira: segmantasyonHesaplamasi.value[segmantasyonIndex.value].SIRA,
        puan: parseFloat(segmantasyonHesaplamasi.value[segmantasyonIndex.value].RATINGPUANI).toFixed(0),
        detail: segmantasyonHesaplamasi.value[segmantasyonIndex.value].DETAILS,
      }

      ctx.emit('segmantasyonDegerleri', segmantasyonDegerleri.value)

      const PUAN = segmantasyonDegerleri.value.puan

      if (PUAN < 50) {
        segmantasyonDegerleri.value.durum = 'durumD'
        segmantasyonDegerleri.value.ibre_renk = '#ff8c5f'
        segmantasyonDegerleri.value.goster = false
      } else if (PUAN >= 50 && PUAN < 70) {
        segmantasyonDegerleri.value.durum = 'durumC'
        segmantasyonDegerleri.value.ibre_renk = '#7ca2be'
        segmantasyonDegerleri.value.goster = false
      } else if (PUAN >= 70 && PUAN < 80) {
        segmantasyonDegerleri.value.durum = 'bronz'
        segmantasyonDegerleri.value.ibre_renk = '#e1b19e'
        segmantasyonDegerleri.value.goster = true
      } else if (PUAN >= 80 && PUAN < 90) {
        segmantasyonDegerleri.value.durum = 'silver'
        segmantasyonDegerleri.value.ibre_renk = '#e6e6e6'
        segmantasyonDegerleri.value.goster = true
      } else if (PUAN >= 90 && PUAN < 100) {
        segmantasyonDegerleri.value.durum = 'gold'
        segmantasyonDegerleri.value.ibre_renk = '#efb442'
        segmantasyonDegerleri.value.goster = true
      }
    }

    const segmantasyonUzerinde = ref(false)

    watch(segmantasyonUzerinde, val => {
      if (val) {
        gsap.set('.segmantasyon-img-daha-fazla-bilgi', { zIndex: 1 })
      } else {
        gsap.set('.segmantasyon-img-daha-fazla-bilgi', { zIndex: 50 })
      }
    })

    const animasyonOlustur = segmantasyonVeriDurumu => {
      if (segmantasyonVeriDurumu) {
        animasyonKayitVar(vm.$vuetify.breakpoint.smAndDown)
      } else {
        animasyonKayitYok()
      }
    }

    onMounted(async () => {
      init()
      const segmantasyonVeriDurumu = await segmantasyonVerileri()
      gsap.to('.segmantasyon-yukleniyor', { autoAlpha: 0 })
      animasyonOlustur(segmantasyonVeriDurumu)
    })

    return {
      segmantasyonItems,
      segmantasyonIndex,
      dataLoading,
      segmantasyonDetailDialog,
      segmantasyonDetailItems,
      segmantasyonDialog,
      segmantasyonBilgileri,
      segmantasyonDegerleri,
      segmantasyonUzerinde,
      simgeler: {
        mdiChartDonutVariant,
        mdiAlertCircle,
        mdiMinus,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
// Değerler
$ekran-type-monitor: 1600px;
$ekran-type-laptop: 1263px;
$ekran-type-tablet: 959px;
$ekran-type-telefon: 600px;

.raporEkran {
  height: calc(100dvh - 131px) !important;

  position: sticky !important;
  top: 102px !important;
  background-image: radial-gradient(circle farthest-corner at 25% 25%, #57507b 0%, #36305d 150%);
  @media screen and (max-width: $ekran-type-tablet) {
    height: 575px !important;
  }

  .v-window,
  .v-window__container,
  .v-window-item {
    height: 100%;
    @media screen and (max-width: $ekran-type-tablet) {
      aspect-ratio: 1;
    }
  }
}

.lds-ellipsis {
  @media screen and (max-width: $ekran-type-telefon) {
    zoom: 0.75;
  }
  div {
    background: #201c30 !important;
  }
}
.segmantasyon-kapsul {
  position: absolute;
  top: 47.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 97.5%;
  user-select: none;
}

.segmantasyon-yuzey {
  width: 100%;
  height: 100%;
}
.segmantasyon-result {
  background: white;
  border-radius: 50%;
  aspect-ratio: 1;
  pointer-events: all;
}
.segmantasyon-img-background {
  z-index: 10;
}
.segmantasyon-img-olcu {
  z-index: 20;
}
.segmantasyon-img-zemin {
  z-index: 30;
}
.segmantasyon-img-one {
  z-index: 40;
}
.segmantasyon-img-mobil-one {
  z-index: 40;
}
.segmantasyon-img-two {
  z-index: 25;
}
.segmantasyon-img-three {
  z-index: 25;
}
.segmantasyon-svg-ibre {
  z-index: 25;
  width: 61%;
  height: 61%;
  fill: white;
}
.segmantasyon-img-daha-fazla-bilgi {
  z-index: 50;
}
.segmantasyon-img-daha-fazla-bilgi-uzerinde {
  z-index: 45;
}
.segmantasyon-daha-fazla-bilgi-buton {
  z-index: 100;
  width: 43%;

  border-radius: 50%;
  aspect-ratio: 1;
  cursor: pointer;
}
.segmantasyon-puan {
  z-index: 60;
  color: #2e2949;
  font-weight: bold;
  line-height: 1;
  font-size: 72px;
  padding-left: calc(95.38px + 5%);
  padding-bottom: 3%;

  @media screen and (max-width: $ekran-type-monitor) {
    font-size: 48px;
    padding-left: calc(63.66px + 5%);
    padding-bottom: 3%;
  }
  @media screen and (max-width: $ekran-type-laptop) {
    font-size: 56px;
    padding-left: calc(74.22px + 5%);
  }

  @media screen and (max-width: $ekran-type-tablet) {
    font-size: 13vw;
    padding-left: initial;
    padding-bottom: 19vw;
  }
  @media screen and (max-width: $ekran-type-telefon) {
    font-size: 10vw;
    padding-left: initial;
    padding-bottom: 18vw;
  }
}
.segmantasyon-sira {
  z-index: 60;
  color: white;
  font-weight: bold;
  line-height: 1;
  font-size: 48px;
  padding-right: 30%;
  padding-bottom: 4%;
  @media screen and (max-width: $ekran-type-monitor) {
    font-size: 32px;
    padding-right: 27%;
  }
  @media screen and (max-width: $ekran-type-laptop) {
    font-size: 48px;
    padding-right: 32%;
  }
  @media screen and (max-width: $ekran-type-tablet) {
    color: #2e2949;
    padding-right: 40%;

    font-size: 7vw;
    padding-bottom: initial;
    padding-top: 3vw;
  }
}

.segmantasyon-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.grafik-baslik {
  position: relative;
  z-index: 11;
  top: -20px;
  left: -20px;
  right: 0;
  width: calc(100% - -35px);
}
</style>
