<template>
  <v-card :disabled="disabled" class="d-flex flex-column" color="#3b355a" rounded="xl" :height="400">
    <v-list color="transparent">
      <v-list-item :dense="$vuetify.breakpoint.xsOnly">
        <v-list-item-icon>
          <v-icon  :size="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 36 : null">
            {{ icons.mdiBullseyeArrow }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :style="!$vuetify.breakpoint.mdAndDown ? null : 'font-size:13px'"> {{ satisHedefi.baslik }} Satış Hedefiniz </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="false">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                class="text-capitalize"
                color="chip"
                depressed
                rounded
                v-bind="attrs"
                v-on="on"
                :loading="disabled"
              >
                Seç
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(madde, i) in satisHedefiMaddeleri"
                :key="'madde' + i"
                @click="satisHedefiVerileri(madde.year, madde.quarter, madde.current)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ madde.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-card-text class="flex-grow-1" :class="satisHedefi.durum" id="satis-hedefiniz">
      <div class="satis-hedefiniz--yuzer">
        <div class="satis-hedefiniz--yuzde">
          <div id="hedefiniz" class="hedefiniz--kapsul">
            <div class="hedefiniz--yuzer">
              <div class="hedefiniz-yuzde">
                <div class="hedefiniz-cizgi"></div>
                <div class="hedefiniz--yazi--sayac-birim">
                  <div class="hedefiniz--yazi">Hedefiniz</div>
                  <div class="hedefiniz--sayac-birim">
                    <span class="hedefiniz--sayac"> {{ satisHedefi.istenilen }} </span>
                    <span class="hedefiniz--birim"> TON </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="gerceklesen-hedefiniz" class="gerceklesen-hedefiniz--kapsul">
            <div class="gerceklesen-hedefiniz--yuzer">
              <div class="gerceklesen-hedefiniz-yuzde">
                <div class="gerceklesen-hedefiniz-cizgi"></div>
                <div class="gerceklesen-hedefiniz--yazi--sayac-birim">
                  <div class="gerceklesen-hedefiniz--yazi">Gerçekleşen</div>
                  <div class="gerceklesen-hedefiniz--sayac-birim">
                    <span class="gerceklesen-hedefiniz--sayac">
                      {{ satisHedefi.gerceklesen }}
                    </span>
                    <span class="gerceklesen-hedefiniz--birim"> TON </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="satis-hedefiniz--yukleniyor lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="satis-hedefiniz-kayit-yok text-center">
        <v-icon size="72" color="primary" class="mb-2 mt-16">{{ icons.mdiAlertCircle }}</v-icon>
        <div class="text-subtitle-2 font-weight-medium primary--text">Kayıt Bulunamadı</div>

        <v-btn class="text-capitalize mt-5" :to="{ name: 'order-to' }" rounded depressed>Sipariş Ver</v-btn>
      </div>
    </v-card-text>
    <v-alert
      v-if="satisHedefi.acikSiparis"
      class="ma-0 pa-0 rounded-0"
      style="border-left: none !important; border-right: none !important"
      
      text
      
    >
      <v-card tile color="transparent">
        <v-card-subtitle class="py-3 px-4 px-md-6 text-caption text-md-body-2"
          >Açık sipariş toplamınız
          <strong
            ><u>{{ satisHedefi.acikSiparis }} Ton</u></strong
          ></v-card-subtitle
        >
      </v-card>
    </v-alert>
    <v-alert
      v-if="!($vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly)"
      :type="!satisHedefi.current ? satisHedefi.statusSlug : satisHedefi.durum == 'kaldi' ? 'warning' : satisHedefi.istenilen === 0 ? 'error':'info'"
      prominent
      dense
      class="satis-hedefiniz--bilgi ma-0 px-6"
      text
      rounded="b-xl t-0"
      style="width: 100%"
    >
      <div class="d-flex align-center">
        <div class="text-subtitle-2 flex-grow-1">
          <div v-if="satisHedefi.durum == 'kaldi'">
            <span>Kalan</span>
            <span class="mx-1 font-weight-black text-decoration-underline"
              >{{ satisHedefi.istenilen - satisHedefi.gerceklesen }} TON</span
            >
            <span>için</span>
            <span class="mx-1 font-weight-black text-decoration-underline">{{ satisHedefi.kalanGun }}</span>
            <span>süreniz var</span>
          </div>
          <div v-else>
            <div v-if="satisHedefi.current">
              <span v-if="satisHedefi.istenilen === 0">Henüz hedefiniz sisteme girilmedi</span>
              <span v-else> Hedef Tamamlandı </span>
            </div>
            <div v-else>
              <div v-if="satisHedefi.statusSlug === 'success'">Geçmiş Hedef Tamamlanmış</div>
              <div v-else>Geçmiş Hedef Tamamlanamamış</div>
            </div>
          </div>
        </div>

        <v-btn
          :color="!satisHedefi.current ? satisHedefi.statusSlug : satisHedefi.durum == 'kaldi' ? 'warning' : 'info'"
          class="text-capitalize"
          :to="{ name: 'order-to' }"
          rounded
          depressed
          >Sipariş Ver</v-btn
        >
      </div>
    </v-alert>
    <v-card-actions class="pa-0" v-else>
      <v-row>
        <v-col cols="12">
          <v-alert
            :color="!satisHedefi.current ? satisHedefi.statusSlug : satisHedefi.durum == 'kaldi' ? 'warning' : 'info'"
            dense
            class="satis-hedefiniz--bilgi ma-0"
            text
            rounded="xl t-0"
          >
            <div class="text-caption flex-grow-1">
              <div v-if="satisHedefi.durum == 'kaldi'">
                <span>Kalan</span>
                <span class="mx-1 font-weight-black text-decoration-underline"
                  >{{ satisHedefi.istenilen - satisHedefi.gerceklesen }} TON</span
                >
                <span>için</span>
                <span class="mx-1 font-weight-black text-decoration-underline">{{ satisHedefi.kalanGun }}</span>
                <span>süreniz var</span>
              </div>
              <div v-else>
                <div v-if="satisHedefi.current">Hedef Tamamlandı.</div>
                <div v-else>
                  <div v-if="satisHedefi.statusSlug === 'success'">Geçmiş Hedef Tamamlanmış</div>
                  <div v-else>Geçmiş Hedef Tamamlanamamış</div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <v-btn block rounded color="secondary" class="text-capitalize" :to="{ name: 'order-to' }" depressed
                >Sipariş Ver</v-btn
              >
            </div>
          </v-alert></v-col
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getCurrentInstance, onMounted, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Plugin
import gsap from 'gsap'

// Icon
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiRecord,
  mdiTimerOutline,
  mdiSend,
  mdiBullseye,
  mdiBullseyeArrow,
  mdiFileCog,
  mdiAlertCircle,
} from '@mdi/js'

export default {
  setup() {
    const disabled = ref(true)
    const satisHedefi = ref({
      istenilen: 0,
      gerceklesen: 0,
      gerceklesenYuzde: 0,
      acik: 0,
      acikYuzde: 0,
      kalanYuzde: 0,
      gecilenYuzde: 0,
      durum: 'gecti',
      cizgi: 70,
      kalanGun: 0,
      baslangicTarihi: null,
      bitisTarihi: null,
      baslik: '',
      current: true,
      statusSlug: null,
    })

    const init = () => {
      gsap.set('.satis-hedefiniz--yuzer', {
        autoAlpha: 0,
      })
      gsap.set('.satis-hedefiniz--bilgi', {
        autoAlpha: 0,
      })
      gsap.set('.satis-hedefiniz-kayit-yok', {
        autoAlpha: 0,
      })
    }

    const animasyonKayitVar = () => {
      gsap.to('.satis-hedefiniz--yuzer', {
        autoAlpha: 1,
      })
      gsap.to('.satis-hedefiniz--bilgi', {
        autoAlpha: 1,
      })

      gsap.to('.hedefiniz-yuzde', {
        width: satisHedefi.value.cizgi + '%',
        duration: satisHedefi.value.durum === 'gecti' ? 3 : 2,
        ease: 'power4.out',
      })

      gsap.to('.gerceklesen-hedefiniz-yuzde', {
        width: satisHedefi.value.gerceklesenYuzde + '%',
        duration: satisHedefi.value.durum === 'gecti' ? 2 : 3,
        ease: 'power4.out',
      })

      gsap.to('.acik-hedefiniz-yuzde', {
        width: 65 + '%',
        duration: satisHedefi.value.durum === 'gecti' ? 2 : 3,
        ease: 'power4.out',
      })
    }

    const animasyonKayitYok = () => {
      gsap.to('.satis-hedefiniz-kayit-yok', {
        autoAlpha: 1,
      })
    }

    const satisHedefiVerileri = async (year, quarter, current = true) => {
      try {
        disabled.value = true
        const payload = {
          method: 'ESMSalTargetNew',
        }
        if (year) payload.year = year
        if (quarter) payload.quarter = quarter

        const data = await store.dispatch('postMethod', payload)

        // Back end hata gönderdi
        if (data.error == 1) throw new Error(response.msg)

        // Back end veri göndermedi
        if (!data.response) throw new Error('Kayıt Bulunamadı')

        satisHedefi.value.baslik = data.response[0].title

        if (data.response[0].target === 0) {
          //return false
        }

        satisHedefi.value.cizgi = 70

        satisHedefi.value.gerceklesen = Number(parseFloat(data.response[0].real).toFixed(0))
        satisHedefi.value.istenilen = Number(parseFloat(data.response[0].target).toFixed(0))
        satisHedefi.value.acikSiparis = Number(parseFloat(data.response[0].open).toFixed(0))

        satisHedefi.value.kalanGun = data.response[0].remaining
        satisHedefi.value.baslangicTarihi = data.response[0].startDate
        satisHedefi.value.bitisTarihi = data.response[0].dueDate

        satisHedefi.value.current = current
        satisHedefi.value.statusSlug = data.response[0].statusSlug

        satisHedefi.value.gerceklesenYuzde = (
          (satisHedefi.value.gerceklesen * satisHedefi.value.cizgi) /
          satisHedefi.value.istenilen
        ).toFixed(2)

        if (satisHedefi.value.istenilen > satisHedefi.value.gerceklesen) {
          satisHedefi.value.durum = 'kaldi'
        } else if (satisHedefi.value.istenilen == satisHedefi.value.gerceklesen) {
          satisHedefi.value.durum = 'esit'
        } else {
          satisHedefi.value.durum = 'gecti'
        }

        if (satisHedefi.value.gerceklesenYuzde < 25) {
          satisHedefi.value.gerceklesenYuzde = 25
        } else if (satisHedefi.value.gerceklesenYuzde > 75) {
          satisHedefi.value.gerceklesenYuzde = 75
          satisHedefi.value.cizgi = 60
        }

        satisHedefi.value.kalanYuzde = satisHedefi.value.cizgi - satisHedefi.value.gerceklesenYuzde
        satisHedefi.value.gecilenYuzde = satisHedefi.value.gerceklesenYuzde - satisHedefi.value.cizgi
        animasyonKayitVar()
        return true
      } catch (e) {
        return false
      } finally {
        disabled.value = false
      }
    }

    const satisHedefiMaddeleri = ref([])
    const satisHedefiMaddeVerileri = async () => {
      try {
        const payload = {
          method: 'ESMSalTargetWithQuarters',
        }
        const data = await store.dispatch('postMethod', payload)

        // Back end hata gönderdi
        if (data.error == 1) throw new Error(response.msg)

        // Back end veri göndermedi
        if (!data.response) throw new Error('Kayıt Bulunamadı')

        satisHedefiMaddeleri.value = data.response

        return true
      } catch (e) {
        return false
      }
    }

    onMounted(async () => {
      init()
      await satisHedefiMaddeVerileri()
      const satisHedefiVeriDurum = await satisHedefiVerileri()

      gsap.to('.satis-hedefiniz--yukleniyor', {
        autoAlpha: 0,
      })

      if (!satisHedefiVeriDurum) {
        animasyonKayitYok()
      }
    })

    return {
      disabled,
      satisHedefi,
      satisHedefiMaddeleri,
      satisHedefiVerileri,
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiRecord,

        mdiBullseye,
        mdiTimerOutline,
        mdiSend,
        mdiBullseyeArrow,

        mdiFileCog,
        mdiAlertCircle,
      },
    }
  },
}
</script>
<style scoped lang="scss">
.lds-ellipsis {
  @media screen and (max-width: 600px) {
    zoom: 0.75;
  }
  div {
    background: #938daf !important;
  }
}
</style>
<style lang="scss">
$ekran-type-monitor: 1600px;
$ekran-type-laptop: 1263px;
$ekran-type-tablet: 959px;
$ekran-type-telefon: 600px;
.satis-hedefiniz--bilgi {
  opacity: 0;
}

.ceyrekHedef {
  background: #3b355a;
}

.satis-hedefiniz-kayit-yok {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#satis-hedefiniz {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 959px) {
    padding: 0 10px;
  }
  &.satis-hedefiniz--kapsul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .satis-hedefiniz--yuzer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 959px) {
      align-items: flex-start;
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .satis-hedefiniz--yuzde {
    position: relative;
    background: #28243d;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    padding: 0 7px;
    @media screen and (max-width: 1600px) {
      height: 50px;
    }
  }
  #hedefiniz {
    &.hedefiniz--kapsul {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    .hedefiniz-yuzde {
      position: relative;
      width: 0%;
      height: 46px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      @media screen and (max-width: 1600px) {
        height: 36px;
      }
    }
    .hedefiniz-cizgi {
      position: absolute;
      background: #ffffff;
      width: 5px;
      height: 80px;
      border-radius: 10px;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 1600px) {
        height: 70px;
      }
    }
    .hedefiniz--yazi--sayac-birim {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 150px;

      .hedefiniz--yazi {
        position: absolute;
        width: 100%;
        top: -40px;
        font-size: 11px;
        letter-spacing: 1px;
      }
      .hedefiniz--sayac-birim {
        font-size: 18px;
        color: white;
        font-weight: bold;
        line-height: 1.2;
        @media screen and (max-width: 1600px) {
          font-size: 16px;
        }
        @media screen and (max-width: 959px) {
          font-size: 13px;
        }
      }
    }
  }

  #gerceklesen-hedefiniz {
    &.gerceklesen-hedefiniz--kapsul {
      z-index: 20;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    .gerceklesen-hedefiniz-yuzde {
      position: relative;

      width: 0%;
      height: 46px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      @media screen and (max-width: 1600px) {
        height: 36px;
      }
    }
    .gerceklesen-hedefiniz-cizgi {
      position: absolute;
      width: 5px;
      height: 80px;
      border-radius: 10px;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 1600px) {
        height: 70px;
      }
    }
    .gerceklesen-hedefiniz--yazi--sayac-birim {
      position: absolute;
      width: 150px;
      top: 50%;
      transform: translateY(-50%);

      .gerceklesen-hedefiniz--yazi {
        position: absolute;
        width: 100%;
        top: -40px;
        font-size: 11px;
        letter-spacing: 1px;
      }
      .gerceklesen-hedefiniz--sayac-birim {
        font-size: 18px;
        color: white;
        font-weight: bold;
        line-height: 1.2;
        @media screen and (max-width: 1600px) {
          font-size: 16px;
        }
        @media screen and (max-width: 959px) {
          font-size: 13px;
        }
      }
    }
  }

  #acik-hedefiniz {
    &.acik-hedefiniz--kapsul {
      z-index: 10;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    .acik-hedefiniz-yuzde {
      position: relative;

      width: 0%;
      height: 46px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      @media screen and (max-width: 1600px) {
        height: 36px;
      }
    }
    .acik-hedefiniz-cizgi {
      position: absolute;
      width: 3px;
      height: 150px;
      border-radius: 10px;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 1600px) {
        height: 120px;
      }
    }
    .acik-hedefiniz--yazi--sayac-birim {
      position: absolute;
      width: 150px;
      top: 50%;
      transform: translateY(-50%);

      .acik-hedefiniz--yazi {
        position: absolute;
        width: 100%;
        top: -77.5px;
        right: 5px;
        color: #ff9800;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 1px;
        @media screen and (max-width: 1600px) {
          top: -80px;
        }
      }
      .acik-hedefiniz--sayac-birim {
        position: absolute;
        bottom: -77.5px;
        left: 0;
        color: #ff9800;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
        @media screen and (max-width: 1600px) {
          font-size: 13px;
          top: 70px;
        }
        @media screen and (max-width: 959px) {
          font-size: 11px;
        }
      }
    }
  }

  #kalan-hedefiniz {
    @media screen and (max-width: 959px) {
      display: none;
    }
    &.kalan-hedefiniz--kapsul {
      opacity: 0;
      z-index: 15;
      position: absolute;
      width: 70%;
      left: calc(0% + 7px);
      top: 50%;
      transform: translateY(-50%);
    }
    .kalan-hedefiniz--yuzer {
    }

    .kalan-hedefiniz-yuzde {
      position: relative;
      width: 100%;
      height: 46px;
      @media screen and (max-width: 1600px) {
        height: 36px;
      }
    }
    .kalan-hedefiniz-cizgi {
      position: absolute;
      left: 50%;
      background: #938daf;
      width: 80%;
      height: 1px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .kalan-hedefiniz--yazi--sayac-birim {
      position: absolute;
      left: 50%;
      text-align: center;
      bottom: -85px;
      width: 320px;
      white-space: nowrap;
      transform: translateX(-50%);
      @media screen and (max-width: 1600px) {
        bottom: -70px;
      }
      .kalan-hedefiniz--yazi {
        font-size: 12px;
      }
      .kalan-hedefiniz--sayac-birim {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  #gecen-hedefiniz {
    &.gecen-hedefiniz--kapsul {
      opacity: 0;
      z-index: 25;
      position: absolute;
      width: 70%;
      left: calc(0% + 7px);
      top: 50%;
      transform: translateY(-50%);
    }

    .gecen-hedefiniz-yuzde {
      position: relative;
      width: 100%;
      height: 46px;
    }
    .gecen-hedefiniz-cizgi {
      position: absolute;
      left: 50%;
      background: white;
      width: 80%;
      height: 1px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .gecen-hedefiniz--yazi--sayac-birim {
      position: absolute;
      left: 0;
      text-align: center;
      bottom: -60px;
      width: 100%;
      white-space: nowrap;
      .gecen-hedefiniz--yazi {
        font-size: 12px;
      }
      .gecen-hedefiniz--sayac-birim {
        font-size: 12px;
      }
    }
  }

  &.kaldi {
    #hedefiniz {
      &.hedefiniz--kapsul {
        z-index: 10;
      }

      .hedefiniz-yuzde {
        background: #3b355a;
      }
      .hedefiniz-cizgi {
        left: 100%;
      }
      .hedefiniz--yazi--sayac-birim {
        left: calc(100% + 20px);
        width: 150px;
        text-align: left;
      }
    }
    #gerceklesen-hedefiniz {
      .gerceklesen-hedefiniz-yuzde {
        background: #938daf;
      }
      .gerceklesen-hedefiniz-cizgi {
        right: 0;
        background: #938daf;
      }
      .gerceklesen-hedefiniz--yazi--sayac-birim {
        right: 20px;
        text-align: right;
      }
    }

    #acik-hedefiniz {
      .acik-hedefiniz-yuzde {
        background: #ff9800;
      }
      .acik-hedefiniz-cizgi {
        right: 0;
        background: #ff9800;
      }
      .acik-hedefiniz--yazi--sayac-birim {
        right: 20px;
        text-align: right;
      }
    }
  }

  &.esit,
  &.gecti {
    #hedefiniz {
      &.hedefiniz--kapsul {
        z-index: 30;
      }

      .hedefiniz-yuzde {
        background: #0d6efd;
      }
      .hedefiniz-cizgi {
        right: 0;
      }
      .hedefiniz--yazi--sayac-birim {
        right: 20px;
        text-align: right;
      }
    }
    #gerceklesen-hedefiniz {
      .gerceklesen-hedefiniz-yuzde {
        background: #6ba0ef;
      }
      .gerceklesen-hedefiniz-cizgi {
        left: 100%;

        background: aliceblue;
      }
      .gerceklesen-hedefiniz--yazi--sayac-birim {
        left: calc(100% + 20px);
        text-align: left;
      }
    }
    #acik-hedefiniz {
      .acik-hedefiniz-yuzde {
        background: #6ba0ef;
      }
      .acik-hedefiniz-cizgi {
        left: 100%;

        background: aliceblue;
      }
      .acik-hedefiniz--yazi--sayac-birim {
        left: calc(100% + 20px);
        text-align: left;
      }
    }
  }

  &.esit {
    .gerceklesen-hedefiniz-cizgi {
      display: none;
    }
  }

  .mobil-kalan-gecen {
    display: none;
    width: 100%;
    @media screen and (max-width: 959px) {
      display: block;
    }
  }
}
</style>
