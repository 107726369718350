<template>
  <v-container :class="!$vuetify.breakpoint.mdAndDown ? 'px-md-0' : null" style="max-width: 100%">
    <v-row v-if="!$vuetify.breakpoint.mdAndDown">
      <template v-if="userData.esmtype === 'BAYI' || userData.esmtype === 'TUCCAR'">
        <v-col md="6">
          <v-row>
            <v-col>
              <CeyrekHedef />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CeyrekAnaliz />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SatisAnaliz />
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6">
          <Segmantasyon />
        </v-col>
      </template>
      <template v-else>
        <v-col md="6">
          <v-row>
            <v-col>
              <CeyrekAnaliz />
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6">
          <SatisAnaliz />
        </v-col>
      </template>
    </v-row>

    <v-row v-else>
      <v-col cols="12" v-if="isSegmantasyon">
        <Segmantasyon />
      </v-col>
      <v-col cols="12" v-if="isSegmantasyon">
        <CeyrekHedef />
      </v-col>
      <v-col cols="12">
        <CeyrekAnaliz />
      </v-col>
      <v-col cols="12">
        <SatisAnaliz />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CeyrekHedef from './CeyrekHedef.vue'
import CeyrekAnaliz from './CeyrekAnaliz.vue'
import SatisAnaliz from './SatisAnaliz.vue'
import Segmantasyon from './Segmantasyon.vue'
import { onMounted, ref } from '@vue/composition-api'
import { mdiRefresh, mdiCircleDouble, mdiMapMarker } from '@mdi/js'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    CeyrekHedef,
    CeyrekAnaliz,
    SatisAnaliz,
    Segmantasyon,
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const isSegmantasyon = ref(false)

    const plasiyerKontrol = () => {
      if (userData.esmtype === 'BAYI' || userData.esmtype === 'TUCCAR') {
        isSegmantasyon.value = true
      }
    }

    onMounted(() => {
      plasiyerKontrol()
    })

    return {
      userData,
      isSegmantasyon,
      icons: {
        mdiCircleDouble,
        mdiRefresh,
        mdiMapMarker,
      },
    }
  },
}
</script>

<style lang="scss"></style>
